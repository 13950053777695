import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const PAGE_TITLE = "Youth Civic Engagement For Peace"
const PAGE_NAME = "engagement"
const EngagementPage = props => {
  return (
    <Layout pageName={PAGE_NAME} headerTitle={PAGE_TITLE}>
      <SEO title={PAGE_TITLE} />
      <section id="about-us" className="container">
        <div className="row">
          <div className="col-sm-12">	  


			<p>
				This program focuses on the most socio-economically disadvantaged groups such as children, young ex-soldiers and women victims of sexual violence.
			</p>
			<p>
			This includes projects such as:
			</p>
            <ul>
				<li>
					Project ADIJ - "Activité de USAID pour le Développement Intégré des Jeunes" (USAID Support for the Coordinated Development of Youth).
				</li>
				<li>
					Project leading to the demobilisation and socio-economic reintroduction of young ex-combatants in the districts of Masisi and Rutshuru.
				</li>
			</ul>

			<br />

            <h2>Youth civic engagement for peace (Vijana wa amani)</h2>
			<p>
              Democratic Republic of Congo government, Nongovernmental
              organizations, United Nations Agencies and other stakeholders in
              the peacebuidling processes and community resiliency in DRCongo
              regularly make decisions about issues and services that directly
              affect youth and disadvantaged women, such as services and
              programs for children protection, education, recreation, health,
              DDRR (Disarmament, Demobilization, Regroupment and reintegration
              of children ex-combatants in civil life), socio – economic
              reintegration, climate change, etc.
            </p>
            <p>
              However, although DRC’s government, NGOs, UN agencies operating in
              Eastern Congo regions often seeks the input of their stakeholders,
              youth themselves tend to have few opportunities to give their
              input on the issues that affect them. But the truth is young
              people are citizens too with rights and responsibilities!
              According to the United Nations Convention on the Rights of the
              Child, they have the legal right to express their views freely and
              fully participate in all matters that impact them.
            </p>
            <p>
              Youth makes the seventy five per cent of the DRC’s population age
              (15 – 35 old), young people are a huge untapped resource for
              organizations and communities. They are the real experts on their
              experiences, needs and interactions with organizations. They can
              offer organizations fresh perspectives on issues, innovative
              problem solving, the ability to rise above traditional boundaries
              in our country DRC to make new connections, and the courage to
              pose tough questions that need to be asked.
            </p>
            <blockquote>
              <p>
                Organizations that engage youth in decision-making processes
                make decisions that are more responsive and appropriate to youth
                needs and interests
              </p>
            </blockquote>
            <p>
              Youth civic engagement for peace program is the concept and
              practice of meaningfully engaging youth in decisions that affect
              them, their peers and their community. Youth civic engagement for
              peace ensures that young people become an integral part of the
              work of organizations, peace processes and communities resiliency
              in Eastern of Congo and that their voices help shape the future.
              Through youth civic engagement, youth are no longer seen as
              recipients of services but as peacebuilders that are actively
              engaged and involved in the issues and processes of peacebuilding.
              Engagement is more than just a dialogue between adults and young
              people; it is about adults and youth working together as equal
              partners to make decisions and create change. Organizations
              practicing youth engagement will benefit from the expertise and
              experiences of youth and will be able to do a better job of
              creating and sustain¬ing the services, opportunities and supports
              that young people need for healthy growth and development.
            </p>
            <blockquote>
              <p>
                adults and youth working together as equal partners to make
                decisions and create change
              </p>
            </blockquote>

            <p>
              Youth civic engagement for peace in Eastern of Congo program
              (VIJANA WA AMANI) helps organizations further positive youth
              development and build the capacity of young people. Organizations
              can improve the quality and quantity of youth opportunities by
              meaningfully engaging young people in the work itself. The
              principles and practices of youth civic engagement described below
              can help build the capacity of organizations intervening in
              Eastern of Congo and communities to ensure that all youth,
              particularly disadvantaged one, believe that they have the
              responsibility and resources needed to make the EASTERN CONGO a
              better place for themselves, their families and their peers.
            </p>
            <p>
              Youth Civic engagement for peace in eastern of Congo, youth are
              gathered from their respective cities, villages, that are called
              Cities of peace and villages of peace, each city of peace have
              small groups that are called YOUTH PEACE CLUB.
            </p>
            <p>
              YOUTH PEACE CLUB is a space for gathering volunteer youth, and
              equipping them with the right educational tools and opportunities
              to actively participate in peacebuilding and recognizing their
              differential needs
            </p>
            <p>
              YPC (Youth peace club) is a framework for peace dialogues, peace
              talks, cultural exchange, sporting, a space for youth to learn
              entrepreneurship, peacebuilding skills, conflicts resolution
              skills
            </p>
            <p>
              YPCs form a youth work groups for community services, for
              educational promotion, health and for environment protection and
              nature conservation actions.
            </p>
          </div>
        </div>
		
		<div className="row">
          <div className="col-sm-6">
		  

		  
		    <br /><br />
		    <h2> Honey for peace</h2>
			<br />
            <p>
              This program aims to improve healthy, social and economical life
              of disadvantaged youth (ex-combatants) and vulnerable rural
              community, to fight against youth unemployment and idleness and to
              prevent idle youth from being recruited in armed groups and
              perpetrating violence in communities. Initiating youth and all the
              community for the environment and biodiversity protection.
            </p>
            <p>
              Beneficiaries will be trained in Beekeeping and in
              entrepreneurship so they are able to produce honey which they will
              be selling to generate incomes. Beekeeping as an INCOME GENERATING
              activity will creating job for youth, and fight idleness and
              unemployment which are some of causes that push youth to be
              enrolled easily in armed groups, and make violence. They will be
              gathered in a cooperative of honey producers and sellers. They
              will grow their incomes and grow local economy.
            </p>
          </div>
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"honey611"} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"honey92"} />
          </div>
          <div className="col-sm-6">
            <h2>
              <br />
            </h2>
            <Image imageKey={"honey1182"} />
          </div>
        </div>

		
		
      </section>
    </Layout>
  )
}

export default EngagementPage
